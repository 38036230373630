.blog-single-wrap {
  display: block;
}

.blog-single-wrap .header {
  margin-bottom: 32px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 12px rgba(100, 95, 136, 0.2);
  overflow: hidden;
}

.blog-single-wrap .header .post-thumb {
  width: 100%;
  height: auto;
  background-color: #f6f5fc;
  overflow: hidden;
}

.blog-single-wrap .header .post-thumb img {
  width: 100%;
}

.blog-single-wrap .post-title {
  font-weight: 500;
  color: #212529;
  font-size: 32px;
  margin-bottom: 15px;
}

.post-content h1,
.post-content h2,
.post-content h3,
.post-content h4 {
  color: #000 !important;
  margin-bottom: 10px !important;
}

.post-content p {
  font-size: 16px !important;
  color: #4e4e4e !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  margin: 0 0 15px !important;
}
.blog-single-wrap .post-content .quote {
  display: block;
  padding: 16px 20px;
  background-color: #791a89;
  color: #fff;
  font-size: 18px;
  border-radius: 8px;
}

.blog-single-wrap .post-content .quote .author {
  display: block;
  margin-top: 16px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8) !important;
}

.widget-box {
  display: block;
  padding: 20px;
  margin-bottom: 32px;
  border-radius: 6px;
  border: 1px solid #edecf5;
  box-shadow: 0 3px 9px rgba(100, 95, 136, 0.15);
}

.widget-title {
  font-size: 24px;
  color: #1c1c1c;
  font-weight: 600;
  margin-bottom: 25px;
}

.search-widget .form-control {
  margin-bottom: 8px;
  background-color: #f6f5fc;
  border-color: transparent;
  box-shadow: none !important;
}

.search-widget .form-control:focus {
  border-color: #dee1e6;
}

.search-widget .btn {
  text-transform: uppercase;
}

.categories {
  position: relative;
  list-style: none;
  padding-left: 0;
}

.categories li a {
  display: block;
  padding: 6px;
  transition: all 0.2s ease;
  font-size: 16px;
  color: #1c1c1c !important;
}

.categories li a:hover {
  color: #791a89 !important;
  text-decoration: none;
  font-weight: 500;
}

.blog-item {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e4e7ee;
}

.blog-item .post-thumb {
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  margin-right: 15px;
  width: 100px;
  height: 80px;
  background-color: #f6f5fc;
  overflow: hidden;
}

.blog-item .post-thumb img {
  width: auto;
  height: 100%;
}

.blog-item .post-title a {
  color: #8e8aad;
  transition: all 0.2s ease;
}

.blog-item .post-title a:hover {
  color: #791a89;
  text-decoration: none;
}

.divider {
  display: block;
  margin-top: 16px;
  margin-bottom: 32px;
  width: 32px;
  height: 3px;
  border-radius: 40px;
  background-color: #791a89;
}
