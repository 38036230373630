.offcanvas {
  height: 100%;
  overflow-y: auto;
  padding: 50px 30px 30px !important;
}

.humberger_menu_overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  content: "";
  z-index: 98;
  visibility: hidden;
  transition: none, 0.6s;
}

.offcanvas img {
  max-width: 100%;
}

.humberger_menu_cart .list-group li {
  font-size: 18px;
  border: none !important;
  background-color: transparent;
  padding-left: 0;
}

.mobile-menu .list-group-item {
  padding-left: 0px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.list-group-item a {
  color: #000;
}

.list-group-item a:hover {
  color: #791a89;
}

.list-group-item a.active {
  color: #791a89;
}

.humberger_menu_contact li {
  padding-left: 0px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.humberger_menu_cart {
  margin-bottom: 25px;
}

.humberger_menu_wrapper .slicknav_menu {
  background: 0 0;
  padding: 0;
  margin-bottom: 30px;
}

.header_top_right_social a {
  margin-right: 10px;
  color: #000;
}
