.navbar {
  padding: 10 0;
  background-color: #fff !important;
  height: auto;
}

.brand-logo {
  width: auto !important;
  height: auto !important;
  margin-top: 3px;
}

.navbar_form {
  height: 45px;
  border: 1px solid #cdcdcd;
  padding: 3px;
  border: none;
  outline: none;
}

.navbar_search {
  flex: 1;
  height: 100%;
  padding: 0 7px;
  border: none;
  outline: none;
  font-size: 15px;
  color: #585858;
  background: #f3f6fa;
  padding-left: 20px;
}

.navbar_search::placeholder {
  color: #767575;
}

.search_button {
  height: 100%;
  padding: 0px 10px;
  font-size: 17px;
  font-weight: 400;
  border: none;
  background-color: #791a89;
  color: #fff;
  cursor: pointer;
  font-family: inherit;
  border-radius: 0 5px 5px 0 !important;
}

.login_button {
  height: 100%;
  font-size: 16px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  font-family: inherit;
}

.btn-close-black {
  color: black;
}

#total {
  position: relative;
  font-size: 65% !important;
  font-weight: 400 !important;
  line-height: 0 !important;
  vertical-align: baseline !important;
}

@media (max-width: 769px) {
  .navbar {
    padding: 5px;
    padding-bottom: 10px !important;
  }

  .navbar-toggler {
    margin-left: 20px;
  }

  .navbar_form {
    margin: 10px 0 !important;
  }
}

@media (max-width: 375px) {
  .brand-logo {
    width: 250px;
    height: 60px;
  }

  .navbar {
    justify-content: space-between;
    background-color: #fff !important;
    height: auto;
  }

  .navbar_form {
    width: 100%;
    height: 45px;
    margin: 10px 0 !important;
  }

  .navbar_search {
    width: 100% !important;
  }

  .navbar-toggler {
    margin-left: 0;
  }
}
