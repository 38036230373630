.carousel-item {
  height: 57vh;
  position: relative;
}

.carousel-item img {
  object-fit: cover;
  height: 57vh;
}

.slider_card {
  position: absolute;
  z-index: 1;
  width: 350px;
  bottom: 18% !important;
  left: 10% !important;
  max-width: 350px;
  background-color: transparent;
  border: none;
  outline: none;
}

.slider_card_category {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 4px;
  color: #791a89;
}

.slider_card_title {
  font-size: 46px;
  color: #252525;
  line-height: 52px;
  font-weight: 500;
  margin: 10px 0;
}

.slider_card_text {
  font-size: 16px;
  color: #6f6f6f;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 15px;
}

.slider_card a {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  text-decoration: none;
  background: #791a89;
  display: inline-block;
  padding: 10px 28px;
  color: #fff !important;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .carousel-item {
    height: auto !important;
    position: relative;
  }

  .carousel-item img {
    object-fit: fill;
    height: auto !important;
  }

  .slider_card {
    position: absolute;
    z-index: 1;
    width: 350px;
    top: 15%;
    bottom: 10% !important;
    left: 3% !important;
    max-width: 350px;
    background-color: transparent;
    border: none;
    outline: none;
  }

  .slider_card_body {
    margin: 0;
    padding: 0;
  }

  .slider_card_category {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 4px;
    color: #791a89;
  }

  .slider_card_title {
    font-size: 18px !important;
    line-height: 15px;
    font-weight: 600;
  }

  .slider_card_text {
    font-size: 16px;
    color: #5d5d5d;
    font-weight: 400;
    line-height: 14px !important;
    margin: 0 0 15px;
  }

  .slider_btn {
    font-size: 12px !important;
    padding: 5px 10px !important;
  }
}

@media screen and (max-width: 800px) and (min-width: 600px) {
  .carousel-item {
    height: auto !important;
    position: relative;
  }

  .carousel-item img {
    object-fit: cover;
    height: auto !important;
  }

  .slider_card {
    position: absolute;
    z-index: 1;
    width: 350px;
    top: 10% !important;
    bottom: 10% !important;
    left: 3% !important;
    max-width: 350px;
    background-color: transparent;
    border: none;
    outline: none;
  }

  .slider_card_body {
    margin: 0;
    padding: 0;
  }

  .slider_card_category {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 4px;
    color: #791a89;
  }

  .slider_card_title {
    font-size: 36px !important;
    line-height: 42px;
    font-weight: 600;
  }

  .slider_card_text {
    font-size: 17px;
    color: #5d5d5d;
    font-weight: 400;
    line-height: 25px;
    margin: 0 0 25px;
  }
}
