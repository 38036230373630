@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  overflow-x: hidden !important;
}

body {
  overflow-x: hidden !important;
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: poppins !important;
}

.align_center {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 28px;
  color: #791a89;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.icon:hover {
  transform: scale(1.1);
}
.button {
  display: block;
  font-size: 14px;
  color: #fff;
  background-color: #791a89;
  padding: 10px 15px;
  border-radius: 9px;
  width: 120px;
}

.container-fluid {
  padding: 0 48px !important;
}

.hr {
  height: 4px;
  width: 80px;
  background: #791a89 !important;
  margin: 0 auto;
  opacity: 1;
  border: none;
}

button:hover {
  color: #f7df1f !important;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #8a159e50;
}

@media (max-width: 769px) {
  .container-fluid {
    padding: 0 10px !important;
  }

  h2 {
    font-size: 30px !important;
  }
}

@media (max-width: 375px) {
  .container-fluid {
    padding: 0 10px !important ;
  }
}
