.hero_section {
  width: 100%;
  padding: 30px 0;
}

.hero_card {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #791a89;
  padding-bottom: 10px;
  border-radius: 5px;
  margin-left: 3px;
  padding: 20px 20px 0;
  margin: 0;
}

.card_image {
  text-align: center;
}

.card_image img {
  width: 100%;
  border-radius: 5px;
}

.card_details {
  padding: 20px;
  color: #fff;
  text-align: center;
}

.card_title {
  font-size: 24px;
  padding: 0;
  color: #fff;
  font-weight: 400;
}

.features_section {
  background-color: #f7df1f;
}

.feature_details {
  padding-left: 20px;
  line-height: 10px;
  height: 40px;
}

.feature_icon {
  width: 32px;
  height: 32px;
  color: #791a89;
}

.feature_title {
  font-size: 16px;
  color: #791a89;
  text-transform: uppercase;
}

.feature_text {
  color: #6f6f6f;
}

/* Categories Section */

.categories_section {
  padding-top: 50px;
}

.categories_title h2 {
  text-align: center;
  font-size: 36px;
}

.categories_title hr {
  height: 4px;
  width: 80px;
  background: #791a89 !important;
  margin: 0 auto;
  opacity: 1;
  border: none;
}

.categories_text p {
  text-align: center;
  padding: 0 26%;
  font-size: 16px;
  color: #6f6f6f;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 15px;
}

/* Featured Products Section */

.featured_product_section {
  padding-top: 50px;
}

.featured_product_title h2 {
  text-align: center;
  font-size: 36px;
}

.featured_product_title hr {
  height: 4px;
  width: 80px;
  background: #791a89 !important;
  margin: 0 auto;
  opacity: 1;
  border: none;
}

.featured_product_text p {
  text-align: center;
  padding: 0 26%;
  font-size: 16px;
  color: #6f6f6f;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 15px;
}

/* Ladies Bag Products Section */

.ladies_product_section {
  padding-top: 50px;
}

.ladies_product_title h2 {
  text-align: center;
  font-size: 36px;
}

.ladies_product_title hr {
  height: 4px;
  width: 80px;
  background: #791a89 !important;
  margin: 0 auto;
  opacity: 1;
  border: none;
}

.ladies_product_text p {
  text-align: center;
  padding: 0 26%;
  font-size: 16px;
  color: #6f6f6f;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 15px;
}

/* Promo Banner Section */

.promo_card a {
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  background-color: #791a89;
  padding: 5px 10px;
  margin-right: 10px;
  color: #fff !important;
}

.promo_card btn:hover {
  color: #f7df1f !important;
  background-color: #791a89;
}

.promo_card_left_link {
  position: absolute;
  z-index: 1;
  float: right !important;
  bottom: 20%;
  right: 10%;
}

.promo_card_right_link {
  position: absolute;
  z-index: 1;
  float: right !important;
  bottom: 20%;
  left: 10%;
}

/* Guaranteed Lowest Prices Products Section */

.price_grnted_product_section {
  padding-top: 50px;
}

.price_grnted_product_title h2 {
  text-align: center;
  font-size: 36px;
}

.price_grnted_product_title hr {
  height: 4px;
  width: 80px;
  background: #791a89 !important;
  margin: 0 auto;
  opacity: 1;
  border: none;
}

.price_grnted_product_text p {
  text-align: center;
  padding: 0 26%;
  font-size: 16px;
  color: #6f6f6f;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 15px;
}

/* Random (You mayb Also like) Products Section */

.random_product_section {
  padding-top: 50px;
}

.random_product_title h2 {
  text-align: center;
  font-size: 36px;
}

.random_product_title hr {
  height: 4px;
  width: 80px;
  background: #791a89 !important;
  margin: 0 auto;
  opacity: 1;
  border: none;
}

.random_product_text p {
  text-align: center;
  padding: 0 26%;
  font-size: 16px;
  color: #6f6f6f;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 15px;
}

/* Store Details Section */

.store_details_section {
  padding-top: 50px;
}

.store_details_title h2 {
  text-align: center;
  font-size: 36px;
}

.store_details_title hr {
  height: 4px;
  width: 80px;
  background: #791a89 !important;
  margin: 0 auto;
  opacity: 1;
  border: none;
}

.store_details_text p {
  text-align: center;
  font-size: 15px;
  color: #6f6f6f;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 15px;
  text-align: start;
}

@media screen and (max-width: 600px) {
  .hero_section {
    width: 100%;
    padding: 25px 0;
  }

  .hero_card {
    height: auto !important;
    display: flex;
    padding: 10px;
    align-items: center;
    flex-grow: 1;
    margin: 0;
    margin-top: -10px;
    margin-bottom: 10px;
  }

  .card_details {
    padding: 23px;
    color: #fff;
    text-align: start;
  }

  .card_title {
    font-size: 20px;
    padding: 0;
  }

  .featrue_box {
    border: none !important;
    border-bottom: 1px solid #2e2e2e !important;
  }

  .categories_text p {
    padding: 0 10%;
  }

  /* Featured Products Section */

  .featured_product_text p {
    padding: 0 10%;
  }

  /* Ladies Bag Products Section */

  .ladies_product_text p {
    padding: 0 10%;
  }

  /* Guaranteed Lowest Prices Products Section */

  .price_grnted_product_text p {
    padding: 0 10%;
  }

  /* Random (You mayb Also like) Products Section */
  .random_product_text p {
    padding: 0 10%;
  }

  .promo_card img {
    height: 50%px;
  }

  .promo_card_btn {
    font-size: 12px !important;
    padding: 3px 10px !important;
    margin-right: 5px;
    background-color: #791a89;
    color: #fff;
  }
}

@media screen and (max-width: 800px) and (min-width: 600px) {
  .hero_card {
    width: 100%;
    height: auto !important;
    padding: 10px;
    align-items: center;
    flex-grow: 1;
    margin: 0;
    margin-top: -10px;
    margin-bottom: 10px;
  }

  .promo_card img {
    height: 50%px;
  }

  .promo_card_btn {
    font-size: 12px !important;
    padding: 3px 10px !important;
    margin-right: 15px;
    background-color: #791a89;
  }
}
