.card-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines to show */
  overflow: hidden;
  font-size: 19px !important;
}

.card-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Number of lines to show */
  overflow: hidden;
  font-weight: normal;
  color: gray !important;
}

.date-icon {
  font-size: 20px !important;
  margin-right: 5px;
}
