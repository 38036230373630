.icon-hover:hover {
  border-color: #791a89 !important;
  background-color: white !important;
  color: #791a89 !important;
}

.icon-hover:hover i {
  color: #791a89 !important;
}

.single_product {
  justify-content: center;
  padding: 52px 0;
}

.single_product_thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.single_product_thumbnails > img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.product_varients {
  flex-wrap: wrap;
  gap: 14px;
  font-size: 12px !important;
  transition: 0.3s all ease-in-out;
}

.product_varients > img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 3px;
}

.product_varients > h6 {
  font-size: 12px;
  margin: 0;
  text-transform: capitalize;
  padding: 1px;
  font-weight: 400;
}

.selected-variant {
  background-color: #791a89;
  color: #fff;
  transform: scale(1.08);
}

.single_product_display {
  width: 650px;
  height: 650px;
  object-fit: cover;
  border-radius: 10px;
}

.selected_image {
  transform: scale(1.12);
}

.product_img img {
  max-width: 100% !important;
  height: auto;
  margin: auto;
  width: 100%;
  padding: 5px;
}

.img-thumbnail {
  min-width: 96px;
  height: 96px;
}

.single_product_title {
  font-size: 26px !important;
  font-weight: 500;
}
.quantity {
  width: 170px;
}

.form-select {
  height: 35px;
}

.add_to_cart {
  font-size: 18px;
}

.add_to_cart .icon-hover {
  padding-left: 10px !important;
  color: #616161;
}

.add_to_cart a:hover {
  transform: scale(1.02);
  transition: all 0.3s ease-in-out;
  background-color: #791a89;
  color: #fff;
}

.add_to_cart_btn {
  background-color: #791a89;
  color: #fff;
  margin-right: 10px;
  transition: 0.3s all ease-in-out;
}

.add_to_cart_btn:hover {
  background-color: #791a89;
  transform: scale(1.05);
  transition: 0.3s all ease-in-out;
  color: #fff;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #791a89 !important;
  color: #fff !important;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  color: #000 !important;
}

.product_description_tabs_item {
  color: #fff !important;
  font-weight: 600;
}

@media (max-width: 769px) {
}

@media (max-width: 375px) {
}
