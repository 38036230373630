.categories_list a {
  text-decoration: none;
  cursor: pointer;
}

.categories_list img {
  border-radius: 50%;
  width: 95px;
  height: 95px;
  margin-bottom: 10px;
}

.categories_list h5 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
