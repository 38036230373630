.profile_sidebar {
  margin-bottom: 35px;
}

.profile_sidebar .ul {
  border-radius: 10px;
}

.profile_sidebar .ul .li {
  list-style: none;
  display: list-item;
}

.profile_sidebar .ul .li a {
  font-size: 18px;
  color: #1c1c1c;
  line-height: 39px;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  padding: 5px;
  padding-left: 15px !important;
}

.profile_sidebar .ul .li a:hover {
  background-color: #791a89;
  color: #fff !important;
}

.profile_sidebar .ul .li .active {
  background-color: #791a89;
  color: #fff !important;
}

.profile_details {
  background-color: #fff;
}
