.dropdown-toggle {
  width: 100%;
}

.dropdown_section {
  background: #791a89;
  padding: 10px 25px 10px 40px;
  cursor: pointer;
  border-radius: 5px;
}

.dropdown_section:hover {
  background: #791a89;
  padding: 10px 25px 10px 40px;
  cursor: pointer;
  border-radius: 5px;
}

.dropdown_section span {
  font-size: 18px;
  font-weight: 600;
}

.dropdown-menu {
  width: 355px;
}

.dropdown-menu {
  z-index: 9999 !important;
  width: calc(100%);
  left: -0.75rem;
}

.dropdown-item {
  background-color: #fff !important;
  color: black !important;
  padding: 10px;
  width: inherit;
}

.nav_links {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: none, 0.3s;
  padding: 5px 0;
  display: block;
  font-weight: 600;
}

.nav_links ul li a {
  color: #252525 !important;
  margin: 0 10px;
  transition: all 0.2s ease-in-out;
}

.nav_links ul li a:hover {
  color: #791a89 !important;
  transform: scale(1.1);
}

.nav_links ul li .active {
  color: #791a89 !important ;
  transform: scale(1.1);
}

.custom-submenu .dropdown-menu {
  left: auto;
  right: 0;
}
@media (max-width: 769px) {
  .main_container {
    padding: 0 35px 0 6px !important;
    margin: 0 !important;
  }

  .dropdown-menu {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .main_container {
    padding: 0 30px 0 0px !important;
    margin: 0 !important;
  }

  .dropdown-menu {
    width: 100%;
  }
}
