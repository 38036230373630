a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
a,
a:hover {
  text-decoration: none !important;
}

.text-black {
  color: #000;
}

.content {
  padding: 7rem 0;
}

.content h2 {
  font-size: 3.5rem;
  font-weight: 700;
}

.content h3 {
  font-size: 2rem;
  font-weight: 700;
}

.form-control {
  border: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.form-control:active,
.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000;
}

.col-form-label {
  color: #000;
}

.heading {
  color: #888;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-weight: 400;
}

.box {
  padding: 40px 70px;
  background: #fff;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
}
.box h3 {
  font-size: 14px;
  margin-bottom: 30px;
  text-align: center;
}

label.error {
  font-size: 12px;
  color: red;
}

#message {
  resize: vertical;
}

#form-message-warning,
#form-message-success {
  display: none;
}

#form-message-warning {
  color: #b90b0b;
}

#form-message-success {
  color: #55a44e;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.submitting {
  text-align: center;
  float: left;
  width: 100%;
  padding: 10px 0;
  display: none;
  font-weight: bold;
  font-size: 12px;
  color: #000;
}

.submit_button {
  background-color: #791a89;
  height: 55px;
  color: #fff;
}

.submit_button:hover {
  background-color: #791a89;
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
}

.w-header {
  height: 4px;
  width: 180px;
  background: #791a89 !important;
  opacity: 1;
  border: none;
}

.card-body i {
  font-size: 33px;
  padding-bottom: 20px;
  color: #791a89;
}

.card-body a {
  color: #212529bf;
}
