.newsletter_section {
  background-color: #791a89;
  padding: 80px 12%;
  margin-top: 60px;
}

.newsletter {
  background-color: #f3f6fa;
}

.newsletter_section img {
  width: 100px !important;
}

.newsletter_section h4 {
  font-size: 22px;
  padding-top: 20px;
}

.newsletter_section p {
  font-size: 14px;
}

.newsletter_form {
  height: 45px;
  padding: 2px;
  border: none;
  outline: none;
}

.newsletter_button {
  font-size: 14px !important;
}

.newsletter_email_section {
  position: relative;
}

.newsletter_email {
  width: 100%;
  height: 56px;
  border-radius: 8px;
  background-color: #fff;
  border: none;
  font-size: 14px;
  padding: 10px 30% 10px 10px;
}

.add_newsletter {
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background-color: #565656;
  border-radius: 8px;
  padding: 7px 15px;
  outline: 0;
  border: none;
}

.add_newsletter,
.ribbon span {
  color: #fff;
  position: absolute;
}

@media screen and (max-width: 600px) {
  .newsletter_section {
    background-color: #791a89;
    padding: 60px 0px !important;
    margin-top: 60px;
  }

  .newsletter_section img {
    width: 80px !important;
  }

  .newsletter_section h4 {
    font-size: 22px;
    padding-top: 20px;
  }

  .newsletter_section p {
    font-size: 14px;
  }

  .newsletter_form {
    height: 45px;
    padding: 2px;
    border: none;
    outline: none;
  }

  .newsletter_button {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 800px) and (min-width: 600px) {
  .newsletter_section {
    background-color: #791a89;
    padding: 80px 10px !important;
    margin-top: 60px;
  }

  .newsletter_section img {
    width: 100px !important;
  }

  .newsletter_section h4 {
    font-size: 26px;
    padding-top: 20px;
  }

  .newsletter_button {
    font-size: 14px;
  }
}
