.header_top {
  background-color: #791a89;
}

.header_top li {
  list-style: none;
  display: inline;
}

.header_top a {
  text-decoration: none;
}
