.append_products {
  padding-top: 30px !important;
}

.text-right {
  text-align: right !important;
}

.scat_long_desc p {
  font-size: 16px;
  color: #6f6f6f;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 15px;
}

.scat_long_desc a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
}

/* Products Sidebar CSS*/

.sidebar_item {
  margin-bottom: 35px;
}

.sidebar_item .ul {
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  padding: 10px;
}
ol,
ul {
  padding: 0;
  margin: 0;
}

.sidebar_item h4 {
  font-size: 24px;
  color: #1c1c1c;
  font-weight: 600;
  margin-bottom: 25px;
}

.sidebar_item .ul .li {
  list-style: none;
  display: list-item;
}

.sidebar_item .ul .li a {
  font-size: 16px;
  color: #1c1c1c;
  line-height: 39px;
  display: block;
  text-decoration: none;
}

.filters {
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  padding: 10px;
}

.filters h4 {
  font-size: 24px;
  color: #1c1c1c;
  font-weight: 600;
  margin-bottom: 25px;
}

.filters h6 {
  margin: 0;
  color: #111;
  font-weight: 400;
}

.filter__item {
  padding-top: 45px;
  border-top: 1px solid #ebebeb;
  padding-bottom: 20px;
}

.filter__sort span {
  font-size: 16px;
  color: #6f6f6f;
  display: inline-block;
}

select {
  word-wrap: normal;
}

option {
  font-weight: normal;
  display: block;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  white-space: nowrap;
}

.cat_products:hover {
  color: #791a89 !important;
  font-weight: 600;
}

.active {
  color: #791a89 !important;
  font-weight: 600;
}
