.product_card {
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.125);
  /* margin: 13px 0; */
  padding: 12px;
}

.product_image {
  max-width: 100%;
  height: 100%;
  text-align: center;
}

.product_image img {
  width: 100%;
  overflow: clip;
  border-radius: 10px;
}

.product_details {
  padding: 20px 5px 0px 5px;
}

.product_details h5 {
  font-size: 18px;
}

.product_title {
  font-size: 16px !important;
  margin-top: 4px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* Number of lines to show */
  overflow: hidden;
}

.product_price {
  color: #791a89;
}

.product_rating {
  color: #edbb0e;
  font-size: 13px;
}

.product_rating_count {
  color: #000;
}

.card_container .btn {
  background-color: #791a89;
  color: #fff;
  font-size: 13px;
}

.card_wishlist {
  color: #791a89 !important;
}

.card_container {
  position: relative;
  overflow: hidden;
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.skeleton-image {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  background-color: #ccc; /* Placeholder color */
  border-radius: 8px;
}

.skeleton-title {
  width: 80%;
  height: 20px;
  background-color: #ccc; /* Placeholder color */
  border-radius: 4px;
}

.skeleton-price {
  width: 50%;
  height: 20px;
  background-color: #ccc; /* Placeholder color */
  border-radius: 4px;
}

.skeleton-button {
  width: 120px;
  height: 40px;
  background-color: #ccc; /* Placeholder color */
  border-radius: 4px;
}

.product_card {
  opacity: 1;
  transition: opacity 0.3s ease-in-out; /* Smooth transition when content loads */
}

.card_container.loading .product_card {
  opacity: 0;
}

@media (min-width: 424px) and (max-width: 769px) {
  .product_card {
    /* margin: 13px 0; */
    padding: 10px;
  }

  .product_image img {
    border-radius: 10px;
  }

  .product_details {
    padding: 10px 5px !important;
  }

  .product_price_container {
    margin: 5px 0px !important;
  }
}

@media screen and (max-width: 600px) {
  .card_container {
    padding: 0px !important;
  }

  .product_card {
    padding: 5px;
    margin: 0px 0;
  }

  .product_image img {
    border-radius: 10px;
  }

  .product_title {
    font-size: 15px !important;
  }

  .product_details {
    padding: 5px 5px;
  }

  .product_details h5 {
    font-size: 14px;
  }

  .product_price {
    color: #791a89;
    font-size: 14px !important;
  }

  .product_price_container {
    margin: 5px 0px !important;
  }

  .fa-star {
    font-size: 12px;
  }
  .product_rating_count {
    font-size: 12px;
    padding-left: 3px;
  }

  .product_card_btn {
    font-size: 12px !important;
    padding: 3px 10px !important;
    background-color: #791a89;
  }

  .product_title {
    font-size: 16px !important;
    margin-top: 4px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Number of lines to show */
    overflow: hidden;
  }
}
