.page_header_section {
  display: flex;
  align-items: center;
  padding: 30px 0;
}

.set_bg {
  background-image: url("../../assets/media/long_banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.page_header_text h2 {
  font-size: 36px;
}

.page_header_option a {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
  position: relative;
  text-decoration: none;
  background-color: transparent;
}

.page_header_option a:after {
  position: absolute;
  right: -12px;
  top: 13px;
  height: 1px;
  width: 10px;
  background: #fff;
  content: "";
}

.page_header_option span {
  display: inline-block;
  font-size: 16px;
  color: #fff;
}
