.icon-hover-primary:hover {
  border-color: #791a89 !important;
  background-color: white !important;
}

.icon-hover-primary:hover i {
  color: #791a89 !important;
}

.icon-hover-danger:hover {
  border-color: #fff !important;
  background-color: #e23545;
  color: #fff !important;
}

.icon-hover-danger:hover i {
  color: #791a89 !important;
}

.sticky {
  position: sticky;
  top: 20px; /* Adjust as needed */
  background-color: #f1f1f1;
  padding: 20px;
}

.price_card .btn-success {
  background-color: #791a89;
  color: white;
  outline: none;
  border: none;
}

.price_card .btn-success:hover {
  transform: scale(1.02);
  transition: all 0.1s ease-in-out;
}
