.coupan {
  background-color: #791a89;
  padding: 8px 10px;
  border: none;
  border-radius: 0px;
}

.table {
  width: 100%;
  margin-bottom: 1rem !important;
  background-color: transparent;
}

.table thead th {
  vertical-align: bottom !important;
  border-bottom: 1px solid #dee2e6 !important;
  padding: 0.75rem;
}

.table td {
  padding: 20px 10px !important;
}

.place_order_btn {
  background-color: #791a89;
  padding: 8px 30px;
  border: none;
  border-radius: 0px;
  width: 100%;
  font-size: 16px;
}
