.footer {
  background: #f3f6fa;
  padding-top: 70px;
  padding-bottom: 0;
}

.footer_widget {
  margin-bottom: 30px;
  overflow: hidden;
}

.footer_about_logo {
  margin-bottom: 15px;
}

.footer_about_logo a {
  display: inline-block;
}

.footer img {
  vertical-align: middle !important;
  border-style: none !important;
}

.footer_widget p {
  font-size: 14px;
  color: #1c1c1c;
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 30px;
}

.footer_widget .footer_widget_social a {
  display: inline-block;
  height: 41px;
  width: 41px;
  font-size: 16px;
  color: #404040;
  border: 1px solid #ededed;
  border-radius: 12%;
  line-height: 38px;
  text-align: center;
  background: rgba(203, 203, 203, 0.23);
  text-decoration: none;
  margin-right: 10px;
}

.footer_widget h6 {
  color: #1c1c1c;
  font-weight: 700;
  margin-bottom: 10px;
}

.footer_widget ul {
  width: 100%;
  float: left;
}
ol,
ul {
  padding: 0;
  margin: 0;
}

.footer_widget ul li {
  list-style: none;
  display: list-item;
}

.footer_widget ul li a {
  color: #1c1c1c;
  font-size: 14px;
  line-height: 32px;
  text-decoration: none;
}

.footer_about {
  margin-bottom: 30px;
}

.footer_about h6 {
  font-weight: 700;
}

.footer_about ul li {
  font-size: 14px;
  color: #1c1c1c;
  line-height: 34px;
  list-style: none;
}

.footer_about a {
  color: #791a89;
  text-decoration: none;
}

.footer_contact_info h6 {
  font-weight: 400 !important;
  line-height: 20px !important;
}

.footer_contact_info a {
  line-height: 0px !important;
}

.download_button {
  vertical-align: middle;
  border-style: none;
}

.download_button img {
  margin-right: 5px;
}

.footer_copyright {
  border-top: 1px solid #ebebeb;
  padding: 15px;
  overflow: hidden;
  margin-top: 20px;
  background-color: #f2eeee;
}

.footer_copyright_text {
  font-size: 14px;
  color: #1c1c1c;
  float: left;
  line-height: 25px;
}

.footer_copyright_text p {
  font-size: 16px;
  color: #6f6f6f;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 15px;
}

.footer_copyright_text a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
}
