.modal {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.modal.fade.show {
  opacity: 1;
  transform: translate(0);
}

.modal.fade {
  opacity: 0;
  transform: translate(0, -50px);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark semi-transparent background */
  z-index: 1050; /* Ensure the overlay is above the modal */
}

.product_img_coloumn {
  height: auto;
  width: 100%;
}

.icon-hover:hover {
  border-color: #791a89 !important;
  background-color: white !important;
  color: #791a89 !important;
}

.icon-hover:hover i {
  color: #791a89 !important;
}

.single_product_display {
  border-radius: 10px;
}

.selected_image {
  transform: scale(1.12);
}

.modal_product_img img {
  max-width: 100% !important;
  margin: auto;
  width: 100%;
  object-fit: cover;
  padding: 5px;
}

.img-thumbnail {
  min-width: 96px;
  height: 96px;
}

.quantity {
  width: 170px;
}

.add_to_cart {
  font-size: 18px;
}

.add_to_cart .icon-hover {
  padding-left: 10px !important;
  color: #616161;
}

.add_to_cart a:hover {
  transform: scale(1.02);
  transition: all 0.3s ease-in-out;
  background-color: #791a89;
  color: #fff;
}

.add_to_cart_btn {
  background-color: #791a89;
  color: #fff;
  margin-right: 10px;
}

.product-counter .btn {
  background-color: #fff;
}
